@import 'config/mixins';

/**
 * Raleway
**/
@include font-face('Raleway', '../fonts/raleway/raleway-regular', normal, normal);
@include font-face('Raleway', '../fonts/raleway/raleway-regular-italic', normal, italic);

@include font-face('Raleway', '../fonts/raleway/raleway-bold', bold, normal);
@include font-face('Raleway', '../fonts/raleway/raleway-bold-italic', bold, italic);

/**
 * Fira sans
**/
@include font-face('Fira Sans', '../fonts/fira-sans/fira-sans-light', 300, normal);
@include font-face('Fira Sans', '../fonts/fira-sans/fira-sans-light-italic', 300, italic);


/**
 * Open sans
**/
@include font-face('Open sans', '../fonts/open-sans/open-sans-bold', bold, normal);