/* background image mixins */
@mixin bg($file:'') {
    background: url('../img/'+$file) transparent no-repeat center center;
}
@mixin bgimg($file:'') {
    background-image: url('../img/'+$file);
}




// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
        eot: '?',
        svg: '#' + str-replace($name, ' ', '_')
    );

    $formats: (
        otf: 'opentype',
        ttf: 'truetype'
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
		font-weight: $weight;
		font-display: swap;
        src: $src;
    }
}



/*  grayscale  */
@mixin grayscale($value){
    -webkit-filter: grayscale($value);
    filter: grayscale($value);
}


/**
 * Responsive mixin
**/
@mixin resp-max($size) {
    @if ( $size == 's' ) {
        @media (max-width: #{$resp-s}px) {
            @content;
        }
    }
    @else if ( $size == 'm' ) {
        @media (max-width: #{$resp-m}px) {
            @content;
        }
    }
    @else if ( $size == 'l' ) {
        @media (max-width: #{$resp-l}px) {
            @content;
        }
    }
    @else {
        @media (max-width: #{$size}px) {
            @content;
        }
    }
}

@mixin resp-min($size) {
    @if ( $size == 's' ) {
        @media (min-width: #{$resp-s + 1}px) {
            @content;
        }
    }
    @else if ( $size == 'm' ) {
        @media (min-width: #{$resp-m + 1}px) {
            @content;
        }
    }
    @else if ( $size == 'l' ) {
        @media (min-width: #{$resp-l + 1}px) {
            @content;
        }
    }
    @else {
        @media (min-width: #{$size}px) {
            @content;
        }
    }
}
